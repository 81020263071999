<template>
  <div class="top">
    <div class="topTop">常见问题</div>
    <div class="topBot">
      当转人工后，商家可以选择在首句欢迎语，排队中提醒或下班后提醒后自动发送常见问题。买家通过点击问题或发送问题编号获得回复
    </div>
  </div>
  <div class="mid">
    <div>生效范围：</div>
    <el-checkbox v-model="first" @change="firstChange">首句欢迎语</el-checkbox>
    <el-checkbox v-model="second" @change="secondChange">排队中提醒</el-checkbox>
    <el-checkbox v-model="third" @change="thirdChange">下班后提醒</el-checkbox>
  </div>
  <div class="footer">
    <Phone type="faq" :obj="commonProblemList"></Phone>
    <div class="showList">
      <div class="showItem" v-for="(item, index) in commonProblemList" :key="index">
        <div class="showItemTop">
          <div>回复[{{ item.keyword }}]{{ item.asking }}</div>
          <div class="actionBox">
            <span class="textBtn" @click="moveUp(index)" v-if="index != 0">上移</span>
            <span
              class="textBtn"
              @click="moveDown(index)"
              v-if="index != commonProblemList.length - 1"
              >下移</span
            >
            <span @click="editProblem(item, index)" class="textBtn">编辑</span>
            <span @click="delProblem(item, index)" class="textBtn">删除</span>
          </div>
        </div>
        <div class="xian"></div>
        <div class="showItemBot">
          {{ item.answer }}
        </div>
      </div>
      <div style="margin-bottom: 10px" v-if="commonProblemList.length < 10">
        <span class="textBtn" @click="addProblemFlag = true">+添加问题</span>
      </div>
    </div>
  </div>
  <el-dialog title="添加问题" v-model="addProblemFlag" width="40%" @close="handClose">
    <div>
      <div class="addBoxTop">
        <div>问题:</div>
        <div>
          <el-input
            :spellcheck="false"
            v-model.trim="ketWord"
            placeholder="关键词"
            style="width: 90px"
            size="small"
            @input="inputKey"
            @blur="blurKey"
          ></el-input>
          <p>{{ keyWordsTips }}</p>
        </div>
        <div>
          <el-input
            :spellcheck="false"
            v-model.trim="problem"
            placeholder="问题不超过15个字"
            size="small"
            @input="inputProblem"
            @blur="blurProblem"
          ></el-input>
          <p>{{ problemTips }}</p>
        </div>
      </div>
      <div class="addBoxBot">
        <div>答案:</div>
        <div class="updateTxt">
          <div>
            <el-input
              ref="sendMsgUpdate"
              :spellcheck="false"
              type="textarea"
              placeholder="这里是问题答案,不超过500个字"
              v-model="updateTxt"
              maxlength="500"
              show-word-limit
              @blur="chatContentChange"
            >
            </el-input>
            <div class="expression">
              <el-popover
                class="addSome"
                v-model:visible="expressionFlag"
                width="300px"
                trigger="click"
              >
                <div>
                  <div class="classStyle">
                    <span
                      @click="expressionListActive = index"
                      v-for="(item, index) in expressionList"
                      :key="index"
                      :style="{
                        background: expressionListActive == index ? '#e7e7e7' : ''
                      }"
                      >{{ item.name }}</span
                    >
                  </div>
                  <div
                    class="emojisList"
                    v-for="(item, index) in expressionList"
                    :key="index"
                    :style="{
                      display: expressionListActive == index ? 'block' : 'none'
                    }"
                  >
                    <div>
                      <span
                        @click="insertEmojis(item1)"
                        v-for="(item1, index1) in item.list"
                        :key="index1"
                        >{{ item1 }}</span
                      >
                    </div>
                  </div>
                </div>
                <template #reference>
                  <i class="iconfont icon-biaoqing"></i>
                </template>
              </el-popover>
            </div>
          </div>
          <p style="color: red; height: 30px; line-height: 30px">{{ updateTxtTips }}</p>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addProblemFlag = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdate">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, inject, nextTick } from 'vue'
import { post } from '@/util/axios.js'
import { api } from '@/util/api.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import Phone from '../../../components/phone.vue'
import { expressionList } from '@/util/emojis.js'
import { inputInsert } from '@/util/common.js'
import _ from 'lodash'
export default {
  components: {
    Phone
  },
  setup() {
    let replyInfo = inject('replyInfo')
    const commonProblemList = inject('commonProblemList')
    const first = ref(true)
    first.value = replyInfo.value.first_welcome_open == 1 ? true : false
    const firstChange = (val) => {
      replyInfo.value.first_welcome_open = val ? 1 : 0
      post(api.setAutomaticReply, {
        first_welcome_open: val ? 1 : 0
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    const second = ref(true)
    second.value = replyInfo.value.service_queue_reminder == 1 ? true : false
    const secondChange = (val) => {
      replyInfo.value.service_queue_reminder = val ? 1 : 0
      post(api.setAutomaticReply, {
        service_queue_reminder: val ? 1 : 0
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    const third = ref(true)
    third.value = replyInfo.value.service_reminder_after_work == 1 ? true : false
    const thirdChange = (val) => {
      replyInfo.value.service_reminder_after_work = val ? 1 : 0
      post(api.setAutomaticReply, {
        service_reminder_after_work: val ? 1 : 0
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    const sendMsgUpdate = ref(null)
    const addProblemFlag = ref(false)
    const updateTxt = ref('')
    const updateTxtTips = ref('')
    let expressionFlag = ref(false)
    let expressionListActive = ref(0)
    let rangePos = reactive({
      start: 1,
      end: 1
    })
    // 检测输入框文字变化
    const chatContentChange = () => {
      rangePos.start = sendMsgUpdate.value.$el.firstElementChild.selectionStart
      rangePos.end = sendMsgUpdate.value.$el.firstElementChild.selectionEnd
      if (updateTxt.value.length == 0) {
        updateTxtTips.value = '答案不能为空'
      } else {
        updateTxtTips.value = ''
      }
    }
    const insertEmojis = (item) => {
      let { content, callback } = inputInsert(item, sendMsgUpdate, updateTxt.value, rangePos)
      updateTxt.value = content
      nextTick(() => {
        callback()
      })
      expressionFlag.value = false
    }
    const editId = ref()
    const editIndex = ref(null)
    const ketWord = ref('')
    const problem = ref('')
    // 关键词错误提示
    const keyWordsTips = ref()
    const inputKey = (val) => {
      if (val.length > 3) {
        keyWordsTips.value = '不超过3个字'
        return false
      } else {
        keyWordsTips.value = ''
        return true
      }
    }
    const blurKey = () => {
      if (!ketWord.value) {
        keyWordsTips.value = '请输入关键字'
        return false
      }
      return true
    }
    // 问题错误提示
    const problemTips = ref()
    const inputProblem = (val) => {
      if (val.length > 15) {
        problemTips.value = '不超过15个字'
        return false
      } else {
        problemTips.value = ''
        return true
      }
    }
    const blurProblem = () => {
      if (!problem.value) {
        problemTips.value = '请输入问题'
        return false
      }
      return true
    }
    // 检测弹框关闭
    const handClose = () => {
      editId.value = ''
      ketWord.value = ''
      problem.value = ''
      // 关键词错误提示
      keyWordsTips.value = ''
      problemTips.value = ''
      updateTxt.value = ''
      updateTxtTips.value = ''
      editIndex.value = null
    }
    // 接口处理排序
    const dealArr = () => {
      let idArr = []
      commonProblemList.value.map((item) => idArr.push(item.id))
      post(api.sortQuestion, {
        id: idArr
      })
    }
    // 确认修改或者添加问题
    const addOrUpdate = () => {
      if (inputKey(ketWord.value) && blurKey() && inputProblem(problem.value) && blurProblem()) {
        if (updateTxt.value.length == 0) {
          updateTxtTips.value = '答案不能为空'
        } else {
          // 满足所有验证
          let obj = {
            keyword: ketWord.value,
            asking: problem.value,
            answer: updateTxt.value
          }
          if (editId.value) obj.id = editId.value
          post(editId.value == '' ? api.addProblem : api.editProblem, obj).then((res) => {
            if (res.code == 0) {
              if (!editId.value) {
                commonProblemList.value.push({
                  id: res.result,
                  keyword: ketWord.value,
                  asking: problem.value,
                  answer: updateTxt.value,
                  rank: 99
                })
                ElMessage.success('添加成功')
              } else {
                commonProblemList.value[editIndex.value].keyword = ketWord.value
                commonProblemList.value[editIndex.value].asking = problem.value
                commonProblemList.value[editIndex.value].answer = updateTxt.value
              }
              addProblemFlag.value = false
            } else {
              ElMessage.error(`${res.msg}`)
            }
          })
        }
      }
    }
    // 点击上移
    const moveUp = (index) => {
      let obj = _.cloneDeep(commonProblemList.value[index - 1])
      commonProblemList.value[index - 1] = _.cloneDeep(commonProblemList.value[index])
      commonProblemList.value[index] = obj
      dealArr()
    }
    // 点击下移
    const moveDown = (index) => {
      let obj = _.cloneDeep(commonProblemList.value[index + 1])
      commonProblemList.value[index + 1] = _.cloneDeep(commonProblemList.value[index])
      commonProblemList.value[index] = obj
      dealArr()
    }
    // 删除该项
    const delProblem = (item, index) => {
      ElMessageBox.confirm('此操作将永久删除该问题, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(api.delProblem, {
            id: item.id
          }).then((res) => {
            if (res.code == 0) {
              commonProblemList.value.splice(index, 1)
              dealArr()
              ElMessage.success('删除成功')
            } else {
              ElMessage.success('删除失败')
            }
          })
        })
        .catch(() => {})
    }
    const editProblem = (item, index) => {
      editId.value = item.id
      ketWord.value = item.keyword
      problem.value = item.asking
      updateTxt.value = item.answer
      editIndex.value = index
      addProblemFlag.value = true
    }
    return {
      sendMsgUpdate,
      first,
      firstChange,
      second,
      secondChange,
      third,
      thirdChange,
      commonProblemList,
      addProblemFlag,
      expressionList,
      updateTxt,
      expressionFlag,
      expressionListActive,
      chatContentChange,
      insertEmojis,
      ketWord,
      problem,
      keyWordsTips,
      problemTips,
      inputKey,
      blurKey,
      inputProblem,
      blurProblem,
      updateTxtTips,
      handClose,
      addOrUpdate,
      moveUp,
      moveDown,
      delProblem,
      editProblem
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  padding: 20px;
  background-color: #f7f8fa;
  .topTop {
    display: flex;
    align-items: center;
    font-size: 15px;
    .el-switch {
      margin-left: 10px;
    }
  }
  .topBot {
    margin-top: 16px;
    font-size: 14px;
    color: #999;
  }
}
.mid {
  margin-top: 10px;
  padding: 15px 20px;
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
}
.footer {
  margin-top: 20px;
  margin-bottom: 80px;
  display: flex;
  .showList {
    margin-left: 20px;
    flex: 1;
    .showItem {
      margin-bottom: 20px;
    }
    .showItemTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .actionBox {
        span {
          margin-left: 10px;
        }
      }
    }
    .xian {
      width: 100%;
      height: 1px;
      background-color: #e5e5e5;
      margin: 8px 0;
    }
    .showItemBot {
      white-space: pre-wrap;
      line-height: 16px;
      color: #9b9b9b;
    }
  }
}
.addBoxTop {
  display: flex;
  & > div:first-child {
    line-height: 32px;
    flex-shrink: 0;
  }
  & > div:nth-child(2) {
    margin: 0 16px;
    flex-shrink: 0;
    & > p {
      width: 100%;
      line-height: 30px;
      height: 30px;
      font-size: 12px;
      color: red;
    }
  }
  & > div:last-child {
    flex: 1;
    flex-shrink: 0;
    & > p {
      line-height: 30px;
      height: 30px;
      width: 100%;
      font-size: 12px;
      color: red;
    }
  }
}
.addBoxBot {
  display: flex;
  & > div:first-child {
    line-height: 32px;
    flex-shrink: 0;
  }
  & > div:last-child {
    flex: 1;
    flex-shrink: 0;
    margin-left: 16px;
  }
}
.updateTxt {
  .el-textarea {
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    :deep(.el-textarea__inner) {
      padding: 10px 0 10px 20px;
      resize: none;
      height: 110px;
      border-radius: 0;
      &:focus {
        border-color: #dcdfe6 !important;
      }
    }
  }
  .expression {
    padding: 0 10px;
    width: 100%;
    height: 33px;
    border: 1px solid #dcdfe6;
    border-width: 0 1px 1px 1px;
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
      cursor: pointer;
      user-select: none;
    }
  }
}
.emojisList {
  width: 100%;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }
  span {
    display: block;
    width: 49px;
    height: 40px;
    cursor: pointer;
    margin: 0 5px 5px 0;
    font-size: 30px;
    user-select: none;
    text-align: center;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}
.el-popover .classStyle {
  padding-bottom: 12px;
  span {
    display: inline-block;
    padding: 5px 4px;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
  }
}
</style>
